import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { BaseLink } from "../../../navigation/link";

export const AnimeMenu = ({ anchorEl, handleMenuClose }) => {
  const d = new Date();
  const dYear = d.getFullYear();
  const dMonth = d.getMonth() + 1;

  const [yearArray, setYearArray] = useState([]);
  const [seasonArray, setSeasonArray] = useState([]);

  const countDate = (y, m) => {
    let s = "";

    if (m > 12) {
      y++;
      s = "Q1";
    } else if (m < 1) {
      y--;
      s = "Q4";
    } else {
      m = m % 12;

      if (m > 0 && m < 4) {
        s = "Q1";
      } else if (m > 3 && m < 7) {
        s = "Q2";
      } else if (m > 6 && m < 10) {
        s = "Q3";
      } else if ((m > 9 && m < 13) || m === 0) {
        s = "Q4";
      } else {
        s = "";
      }
    }
    setYearArray((yearArray) => [...yearArray, y]);
    setSeasonArray((seasonArray) => [...seasonArray, s]);
  };

  useEffect(() => {
    countDate(dYear, dMonth - 3);
    countDate(dYear, dMonth);
    countDate(dYear, dMonth + 3);
  }, [dMonth, dYear]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <BaseLink link="/anime">
        <MenuItem onClick={handleMenuClose}>動畫一覽</MenuItem>
      </BaseLink>

      <BaseLink link="/anime/date/0000">
        <MenuItem onClick={handleMenuClose}>未定放映日動畫</MenuItem>
      </BaseLink>

      <BaseLink link={`/anime/date/${yearArray[0]}/${seasonArray[0]}`}>
        <MenuItem onClick={handleMenuClose}>上季動畫</MenuItem>
      </BaseLink>

      <BaseLink link={`/anime/date/${yearArray[1]}/${seasonArray[1]}`}>
        <MenuItem onClick={handleMenuClose}>今季動畫</MenuItem>
      </BaseLink>

      <BaseLink link={`/anime/date/${yearArray[2]}/${seasonArray[2]}`}>
        <MenuItem onClick={handleMenuClose}>下季動畫</MenuItem>
      </BaseLink>
    </Menu>
  );
};
