import { Tooltip } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import Icon2 from "../../icon";
import Theme from "./components/theme";
import TranslationBread from "./components/translationbread";

const Footer = () => {
  return (
    <footer className="m-auto h-auto select-none border-t-2 border-neutral bg-accent py-4 text-center">
      <div className="grid grid-cols-4">
        <ul>{/* empty */}</ul>
        <ul>{/* empty */}</ul>

        <ul className="">
          <li className="footer-title m-auto w-fit border-b-2 border-primary-content">
            一覽
          </li>
          <li>
            <Link to="/anime">動畫</Link>
          </li>
          <li>
            <Link to="/company">公司</Link>
          </li>
          <li>
            <Link to="/staff">工作人員</Link>
          </li>
          <li>
            <Link to="/actor">演員・聲優</Link>
          </li>
          <li>
            <Link to="/character">角色</Link>
          </li>
          <li>
            <Link to="/music">音樂</Link>
          </li>
          {/* <li><Link to="/musician">音樂家</Link></li> */}
          <li>
            <Link to="/tag">標籤</Link>
          </li>
        </ul>

        <ul>
          <li className="footer-title m-auto w-fit border-b-2 border-primary-content">
            其他
          </li>
          <li>
            <Link to="/news">最新消息</Link>
          </li>
          <li>
            <Link to="/calendar">月曆</Link>
          </li>
          <li>
            <Link to="/tool">工具</Link>
          </li>
          <li>
            <Link to="/description">說明書</Link>
          </li>
          <li>
            <Link to="/friendship">友情連結</Link>
          </li>
        </ul>
      </div>

      <div className="grid auto-cols-min grid-flow-col justify-center p-4 md:justify-end">
        <Theme />
        <TranslationBread />
      </div>

      <div className="mt-4 ml-4">
        <Tooltip title="Facebook" placement="top">
          <a
            href="https://www.facebook.com/Grid-Anime-113086021021767"
            target="_blank"
            rel="noreferrer noopener"
            className="mr-4"
          >
            <Icon2 icon="facebook" />
          </a>
        </Tooltip>
        <Tooltip title="instagram" placement="top">
          <a
            href="https://www.instagram.com/gridanime_com/"
            target="_blank"
            rel="noreferrer noopener"
            className="mr-4"
          >
            <Icon2 icon="instagram" />
          </a>
        </Tooltip>
        <Tooltip title="YouTube" placement="top">
          <a
            href="https://www.youtube.com/channel/UCGg_30zGXmsLaRc1PoJRs5Q"
            target="_blank"
            rel="noreferrer noopener"
            className="mr-4"
          >
            <Icon2 icon="youtube" />
          </a>
        </Tooltip>
        <Tooltip title="ko-fi" placement="top">
          <a
            href="https://ko-fi.com/gridanime"
            target="_blank"
            rel="noreferrer noopener"
            className="mr-4"
          >
            <Icon2 icon="coffee" />
          </a>
        </Tooltip>
      </div>
      <Link to="/" className="m-auto font-VT323">
        Grid Anime
      </Link>
    </footer>
  );
};
export default Footer;
