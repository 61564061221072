import React from "react";
import { Helmet } from "react-helmet";

const Noindex = (props) => {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="robots" content="noindex" />
    </Helmet>
  );
};
export default Noindex;
