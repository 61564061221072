import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Main from "../../components/base/main/";
import Noindex from "../../components/seo/noindex";

import { breakWord, defaultTitle } from "../../components/head";

const Error404 = () => {
  const title = "404" + breakWord + defaultTitle;

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <Main>
      <Noindex title={title} />

      <div className="grid h-96 cursor-default select-none">
        <div className="m-auto">
          <Link to="/">
            <h1 className=" text-8xl">404</h1>
            <p className="text-right text-xs">你揾我唔到</p>
          </Link>
        </div>
      </div>
    </Main>
  );
};
export default Error404;
