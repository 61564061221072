import Tooltip from "@mui/material/Tooltip";

const MuiTooltip = (props) => {
  return (
    <Tooltip title={props.title} followCursor={props.followCursor} placement="top">
      {props.children}
    </Tooltip>
  );
};

export default MuiTooltip;
