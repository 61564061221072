import React from "react";

const Theme = () => {
  const html = document.querySelector("html");

  const changeTheme = (props) => {
    html.setAttribute("data-theme", props);
  };

  const modeList = [
    "gridanime",
    "light",
    "dark",
    "cupcake",
    "bumblebee",
    "emerald",
    "corporate",
    "synthwave",
    "retro",
    "cyberpunk",
    "valentine",
    "halloween",
    "garden",
    "forest",
    "aqua",
    "lofi",
    "pastel",
    "fantasy",
    "wireframe",
    "black",
    "luxury",
    "dracula",
    "cmyk",
    "autumn",
    "business",
    "acid",
    "lemonade",
    "night",
    "coffee",
    "winter",
  ];

  const item = [];

  modeList.forEach((theme) => {
    item.push(
      <li key={theme} data-theme={theme} onClick={() => changeTheme(theme)}>
        <div className="w-full bg-base-100 text-base-content hover:bg-base-300">
          <div className="grid grid-cols-5">
            <div className="col-span-4 text-left">{theme}</div>
            <div>
              <div className="h-2 w-10 bg-primary "></div>
              <div className="h-2 w-10 bg-secondary"></div>
              <div className="h-2 w-10 bg-accent"></div>
              <div className="h-2 w-10 bg-neutral"></div>
            </div>
          </div>
        </div>
      </li>
    );
  });

  return (
    <div className="dropdown-hover dropdown-top dropdown mx-8">
      <label className="btn w-32 p-2 md:w-52">形態轉換</label>
      <ul className="dropdown-content menu grid h-fit max-h-72 w-full overflow-y-auto border-4 border-base-300 bg-base-200">
        {item}
      </ul>
    </div>
  );
};

export default Theme;
