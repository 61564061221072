import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGoogle,
  faFacebook,
  faYoutube,
  faAmazon,
  faInstagram,
  faLine,
  faTwitter,
  faVimeo,
  faWeibo,
  faYahoo,
  faPinterest,
  faSpotify,
  faWikipediaW,
  faGithub,
  faApple,
} from "@fortawesome/free-brands-svg-icons";

import {
  faHome,
  faTv,
  faFilm,
  faBuilding,
  faPlay,
  faBook,
  faCoffee,
  faLaptop,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faCalendar,
  faClipboard,
  faD,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

const IconColor = (icon) => {
  switch (icon) {
    case "facebook":
      return "#4267B2";
    case "youtube":
    case "youtubemusic":
      return "#FF0000";
    case "amazon":
      return "#FF9900";
    case "instagram":
      return "#833AB4";
    case "twitter":
      return "#1DA1F2";
    case "pinterest":
      return "#E60023";
    case "spotify":
      return "#1DB954";
    default:
      return "#FFFFFF";
  }
};

const Icon2 = (props) => {
  let icon = "";
  let color = "";

  if (props.color !== undefined) {
    color = props.color;
  } else if (props.defaultColor) {
    color = IconColor(props.icon);
  }

  switch (props.icon) {
    case "web":
      icon = faLaptop;
      break;
    case "facebook":
      icon = faFacebook;
      break;
    case "google":
      icon = faGoogle;
      break;
    case "youtube":
      icon = faYoutube;
      break;
    case "youtubemusic":
      icon = faPlay;
      break;
    case "amazon":
      icon = faAmazon;
      break;
    case "instagram":
      icon = faInstagram;
      break;
    case "line":
      icon = faLine;
      break;
    case "twitter":
      icon = faTwitter;
      break;
    case "vimeo":
      icon = faVimeo;
      break;
    case "weibo":
      icon = faWeibo;
      break;
    case "yahoo":
      icon = faYahoo;
      break;
    case "pinterest":
      icon = faPinterest;
      break;
    case "spotify":
      icon = faSpotify;
      break;
    case "wiki":
      icon = faWikipediaW;
      break;
    case "github":
      icon = faGithub;
      break;
    case "apple":
    case "applemusic":
      icon = faApple;
      break;
    case "coffee":
      icon = faCoffee;
      break;

    case "home":
      icon = faHome;
      break;
    case "film":
      icon = faFilm;
      break;
    case "building":
      icon = faBuilding;
      break;
    case "book":
      icon = faBook;
      break;
    case "angle-up":
      icon = faAngleUp;
      break;
    case "angle-down":
      icon = faAngleDown;
      break;
    case "angle-left":
      icon = faAngleLeft;
      break;

    case "angle-right":
      icon = faAngleRight;
      break;

    case "calendar":
      icon = faCalendar;
      break;
    case "clipboard":
      icon = faClipboard;
      break;
    case "tv":
      icon = faTv;
      break;

    case "disneyplus":
      return (
        <span>
          <FontAwesomeIcon icon={faD} color={color === true ? "#ffffff" : ""} />
          <FontAwesomeIcon
            icon={faPlus}
            color={color === true ? "#ffffff" : ""}
          />
        </span>
      );

    case "viu":
      return (
        <svg
          className="my-auto inline"
          viewBox="0 0 35 35"
          height="20"
          width="20"
        >
          <path
            fill="#FFBF00"
            d="M23.7 15.6 23.7 15.6C23.7 14 22.8 12.6 21.5 11.9L10.7 7.1C10.6 7.1 10.6 7 10.5 7C10.2 7 10 7.2 10 7.6V10.3C10 10.6 10.2 11 10.5 11.2L19.2 14.8C19.5 14.9 19.8 15.2 19.8 15.6L19.8 15.6C19.8 15.9 19.6 16.3 19.2 16.4L10.5 20C10.2 20.1 10 20.4 10 20.9V23.8C10 24.1 10.2 24.4 10.5 24.4C10.6 24.4 10.7 24.4 10.7 24.3L21.5 19.3C22.8 18.5 23.7 17.1 23.7 15.6L23.7 15.6ZM30.1 15.6C30.1 23.7 23.5 30.3 15.4 30.3C7.1 30.3.5 23.7.5 15.6S7.1.9 15.2.9C23.4.7 30.1 7.4 30.1 15.6M15.2 3.2C8.4 3.2 2.9 8.7 2.9 15.4S8.4 27.6 15.2 27.6C22 27.6 27.5 22.1 27.5 15.4C27.7 8.7 22.1 3.2 15.2 3.2"
          />
        </svg>
      );

    case "netflix":
      return (
        <svg
          className="my-auto inline"
          viewBox="0 0 27 27"
          height="20"
          width="20"
        >
          <path
            fill="#e50914"
            d="M5.398 0v.006c3.028 8.556 5.37 15.175 8.348 23.596c2.344.058 4.85.398 4.854.398c-2.8-7.924-5.923-16.747-8.487-24zm8.489 0v9.63L18.6 22.951c-.043-7.86-.004-15.913.002-22.95zM5.398 1.05V24c1.873-.225 2.81-.312 4.715-.398v-9.22z"
          ></path>
        </svg>
      );

    case "bilibili":
      return (
        <svg
          className="my-auto inline"
          viewBox="0 0 32 32"
          height="20"
          width="20"
        >
          <path
            fill="#00a1d6"
            d="M17.813 4.653h.854c1.51.054 2.769.578 3.773 1.574c1.004.995 1.524 2.249 1.56 3.76v7.36c-.036 1.51-.556 2.769-1.56 3.773s-2.262 1.524-3.773 1.56H5.333c-1.51-.036-2.769-.556-3.773-1.56S.036 18.858 0 17.347v-7.36c.036-1.511.556-2.765 1.56-3.76c1.004-.996 2.262-1.52 3.773-1.574h.774l-1.174-1.12a1.234 1.234 0 0 1-.373-.906c0-.356.124-.658.373-.907l.027-.027c.267-.249.573-.373.92-.373c.347 0 .653.124.92.373L9.653 4.44c.071.071.134.142.187.213h4.267a.836.836 0 0 1 .16-.213l2.853-2.747c.267-.249.573-.373.92-.373c.347 0 .662.151.929.4c.267.249.391.551.391.907c0 .355-.124.657-.373.906zM5.333 7.24c-.746.018-1.373.276-1.88.773c-.506.498-.769 1.13-.786 1.894v7.52c.017.764.28 1.395.786 1.893c.507.498 1.134.756 1.88.773h13.334c.746-.017 1.373-.275 1.88-.773c.506-.498.769-1.129.786-1.893v-7.52c-.017-.765-.28-1.396-.786-1.894c-.507-.497-1.134-.755-1.88-.773zM8 11.107c.373 0 .684.124.933.373c.25.249.383.569.4.96v1.173c-.017.391-.15.711-.4.96c-.249.25-.56.374-.933.374s-.684-.125-.933-.374c-.25-.249-.383-.569-.4-.96V12.44c0-.373.129-.689.386-.947c.258-.257.574-.386.947-.386zm8 0c.373 0 .684.124.933.373c.25.249.383.569.4.96v1.173c-.017.391-.15.711-.4.96c-.249.25-.56.374-.933.374s-.684-.125-.933-.374c-.25-.249-.383-.569-.4-.96V12.44c.017-.391.15-.711.4-.96c.249-.249.56-.373.933-.373z"
          ></path>
        </svg>
      );

    default:
      icon = faLaptop;
      break;
  }

  return <FontAwesomeIcon icon={icon} color={color} />;
};

export default Icon2;
