import React from "react";

const Loading = () => {
  return (
    <div
      className="m-auto grid h-screen w-screen select-none grid-rows-6 items-center justify-center text-center"
      aria-label="Now Loading..."
    >
      <div className="row-span-5 animate-pulse">Loading...</div>
      <div className="font-VT323">Grid Anime</div>
    </div>
  );
};

export default Loading;
