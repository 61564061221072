import React from "react";
import { useLocation } from "react-router-dom";

import { BaseLink } from "../../navigation/link";

import Tooltip from "../../tooltip";
import { AnimeMenu } from "./components/menu";

const Navbar = () => {
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <nav className="m-2 select-none py-2 md:m-10 lg:text-xl">
      <ul className="auto-col-max m-auto grid grid-flow-col grid-cols-none gap-4 text-center">
        <li
          className={`${
            location.pathname.includes("/anime") ? "text-primary" : ""
          }`}
        >
          <Tooltip title="動畫">
            <button onClick={handleMenuOpen}>動畫</button>
          </Tooltip>
          <AnimeMenu anchorEl={anchorEl} handleMenuClose={handleMenuClose} />
        </li>

        <li
          className={`${
            location.pathname.includes("/company") ? "text-primary" : ""
          }`}
        >
          <Tooltip title="公司・工作室">
            <button>
              <BaseLink link="/company">公司・工作室</BaseLink>
            </button>
          </Tooltip>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
