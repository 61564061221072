import React from "react";
import ReactDOM from "react-dom";

//CSS
import "./styles/index.css";
import "./styles/tag.css";
import "./styles/mui.css";
import "./styles/scrollbar.css";
import "./styles/container.css";

import "./i18n";

import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
