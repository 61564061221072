import React, { useState, useEffect } from "react";

const ToTop = () => {
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [showScrollBottom, setShowScrollBottom] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    window.addEventListener("scroll", checkScrollBottom);
  }, []);

  const style = {
    base: "hidden fixed mx-2 px-2 py-1 right-0 text-xs cursor-pointer select-none opacity-50 duration-500",
    border: "border-2 rounded-full",
    hover: "hover:opacity-100 hover:text-primary hover:border-primary",
  };

  const checkScrollTop = () => {
    if (window.pageYOffset > 200) {
      setShowScrollTop(true);
    } else {
      setShowScrollTop(false);
    }
  };

  const checkScrollBottom = () => {
    if (window.pageYOffset > document.body.scrollHeight - 1000) {
      setShowScrollBottom(false);
    } else {
      setShowScrollBottom(true);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  return (
    <div>
      {/* top */}
      <div
        className={`${showScrollTop ? "md:grid" : "md:hidden"} ${style.base} ${
          style.border
        } ${style.hover} top-10`}
        onClick={scrollTop}
      >
        Top
      </div>

      {/* bottom */}
      <div
        className={`${showScrollBottom ? "md:grid" : "md:hidden"} ${
          style.base
        } ${style.border} ${style.hover} bottom-10`}
        onClick={scrollBottom}
      >
        Bottom
      </div>
    </div>
  );
};

export default ToTop;
