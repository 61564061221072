import Header from "../header/";
import Navbar from "../navbar/";
import Footer from "../footer/";
import Totop from "./components/totop";
import ScrollToTop from "../../../util/scrolltotop";

const Main = (props) => {
  return (
    <div className="min-h-screen">
      <Header />
      <Navbar />
      <main>{props.children}</main>
      <Totop />
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Main;
