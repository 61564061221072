import React, { Suspense, lazy} from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

// Error
import Error404 from "./pages/error/404";
import Loading from "./components/loading";

// Home
const Home = lazy(() => import("./pages/home/"));
// Anime
const Animelist = lazy(() => import("./pages/anime/index"));
const Anime = lazy(() => import("./pages/anime/[aid]/"));
const Datelist = lazy(() => import("./pages/anime/[date]/"));
const Date = lazy(() => import("./pages/anime/[date]/[season]/"));
// Company
const Companylist = lazy(() => import("./pages/company/"));
const Company = lazy(() => import("./pages/company/[cid]/"));
// Staff
const Stafflist = lazy(() => import("./pages/staff/"));
const Staff = lazy(() => import("./pages/staff/[staff]/"));
// Actor
const Actorlist = lazy(() => import("./pages/actor/"));
const Actor = lazy(() => import("./pages/actor/[actor]/"));
// Character
const Characterlist = lazy(() => import("./pages/character/"));
const Character = lazy(() => import("./pages/character/[character]/"));
// Music
const Musiclist = lazy(() => import("./pages/music/"));
const Music = lazy(() => import("./pages/music/[music]/"));
const Musician = lazy(() => import("./pages/musician/"));
// Tag
const Taglist = lazy(() => import("./pages/tag/"));
const Tag = lazy(() => import("./pages/tag/[tag]/"));
// Playlist
const Playlist = lazy(() => import("./pages/playlist/[playlistID]/"));
// Other
const FAQ = lazy(() => import("./pages/faq/"));
const Live247 = lazy(() => import("./pages/live247/"));
const About = lazy(() => import("./pages/about/"));
const Keyword = lazy(() => import("./pages/keyword/"));
const Calendar = lazy(() => import("./pages/calendar/"));
const News = lazy(() => import("./pages/news/"));
const Description = lazy(() => import("./pages/description/"));
const Friendship = lazy(() => import("./pages/friendship"));

// Tool
const Test = lazy(() => import("./pages/test/"));
const Tool = lazy(() => import("./pages/tool/"));
const StringCase = lazy(() => import("./pages/tool/stringcase/"));
const YtIframe = lazy(() => import("./pages/tool/ytiframe/"));

const App = () => {
  return (
    <div id="App">
      <Router>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/anime/*" element={<Animelist />} />
            <Route path="/anime/:aid" element={<Anime />} />
            <Route path="/anime/date/*" element={<Datelist />} />
            <Route path="/anime/date/:year/*" element={<Date />} />
            <Route path="/anime/date/:year/:season/*" element={<Date />} />
            <Route path="/company/*" element={<Companylist />} />
            <Route path="/company/:cid" element={<Company />} />
            <Route path="/character/*" element={<Characterlist />} />
            <Route path="/character/:character" element={<Character />} />
            <Route path="/actor/*" element={<Actorlist />} />
            <Route path="/actor/:cast" element={<Actor />} />
            <Route path="/staff/*" element={<Stafflist />} />
            <Route path="/staff/:staff" element={<Staff />} />
            <Route path="/music/*" element={<Musiclist />} />
            <Route path="/music/:music" element={<Music />} />
            <Route path="/musician/:creator" element={<Musician />} />
            <Route path="/tag/*" element={<Taglist />} />
            <Route path="/tag/:tag" element={<Tag />} />
            <Route path="/playlist/:playlistID" element={<Playlist />} />
            <Route path="/live247/*" element={<Live247 />} />
            <Route path="/faq/*" element={<FAQ />} />
            <Route path="/about/*" element={<About />} />
            <Route path="/keyword/*" element={<Keyword />} />
            <Route path="/calendar/*" element={<Calendar />} />
            <Route path="/news/*" element={<News />} />
            <Route path="/description/*" element={<Description />} />
            <Route path="/friendship/*" element={<Friendship />} />
            <Route path="/tool/*" element={<Tool />} />
            <Route path="/tool/stringcase" element={<StringCase />} />
            <Route path="/tool/ytiframe" element={<YtIframe />} />
            <Route path="/test" element={<Test />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
