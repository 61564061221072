import React from "react";
import { Helmet } from "react-helmet";

export const defaultSite_name = "Grid Anime";
export const defaultUrl = "https://gridanime.com";
export const defaultTitle = "Grid Anime 動畫新番舊番整合";
export const defaultDescription =
  "日本動畫新番舊番 (PV,CM,Staff,Cast,音樂,串流平台等) 資訊整合";

export const defaultKeywords = "Grid Anime";
export const defaultShortTailKeywords = "日本動畫,動畫線上看,新番,舊番";
export const defaulLongTailtKeywords = "動畫在線觀看,";

export const defaultImage = "";
export const defaultType = "website";
export const breakWord = " | ";

const Head = ({
  id,
  page,
  anime,
  title,
  description,
  keywords,
  image,
  season,
  year,
  num,
  info,
}) => {
  let canonical = "https://gridanime.com/";
  keywords = `${defaultKeywords},${defaultShortTailKeywords},${defaulLongTailtKeywords}`;
  switch (page) {
    case "main":
      canonical = defaultUrl;
      title = defaultTitle;
      description = `主頁${breakWord}${defaultDescription}${breakWord}${defaultTitle}`;
      break;

    case "anime:aid":
      let n_anime;
      if (anime.hk === anime.jp) {
        n_anime = anime.hk;
      } else {
        n_anime = anime.hk + " (" + anime.jp + ")";
      }
      canonical += "anime/" + id;
      description = `動畫「${n_anime}」${defaultDescription} | 日本首播日期: ${info.date}${breakWord}動畫${breakWord}${defaultTitle}`;
      title = `${n_anime}${breakWord}動畫${breakWord}${defaultTitle}`;
      keywords = `${anime.hk},${anime.jp},${anime.en},${anime.tw},${defaultKeywords},${defaultShortTailKeywords},${defaulLongTailtKeywords}`;
      break;

    case "anime:date:year":
    case "anime:date:year:season":
      canonical += "anime/date/" + id;
      const currentNum = `,目前收錄${num}套新作動畫`;
      switch (season) {
        case "Q1":
          title = `${year}年1月(冬季)動畫${breakWord}新番表${breakWord}${defaultTitle}`;
          description = `${year}年1月(冬季)動畫${breakWord}新番表${breakWord}日本電視•電影•ONA•OVA•OAD動畫${currentNum}${breakWord}${year}新番 1月新番 2月新番 3月新番 冬季新番${breakWord}${defaultTitle}`;
          keywords = `${year}年1月新番,${year}年冬新番,${year}年一月新番,${defaultKeywords},${defaultShortTailKeywords},${defaulLongTailtKeywords}`;
          break;
        case "Q2":
          title = `${year}年4月(春季)動畫${breakWord}新番表${breakWord}${defaultTitle}`;
          description = `${year}年4月(春季)動畫${breakWord}新番表${breakWord}日本電視•電影•ONA•OVA•OAD動畫${currentNum}${breakWord}${year}新番 4月新番 5月新番 6月新番 春季新番${breakWord}${defaultTitle}`;
          keywords = `${year}年4月新番,${year}年春新番,${year}年四月新番,${defaultKeywords},${defaultShortTailKeywords},${defaulLongTailtKeywords}`;
          break;
        case "Q3":
          title = `${year}年7月(夏季)動畫${breakWord}新番表${breakWord}${defaultTitle}`;
          description = `${year}年7月(夏季)動畫${breakWord}新番表${breakWord}日本電視•電影•ONA•OVA•OAD動畫${currentNum}${breakWord}${year}新番 7月新番 8月新番 9月新番 夏季新番${breakWord}${defaultTitle}`;
          keywords = `${year}年7月新番,${year}年夏新番,${year}年七月新番,${defaultKeywords},${defaultShortTailKeywords},${defaulLongTailtKeywords}`;
          break;
        case "Q4":
          title = `${year}年10月(秋季)動畫${breakWord}新番表${breakWord}${defaultTitle}`;
          description = `${year}年10月(秋季)動畫${breakWord}新番表${breakWord}日本電視•電影•ONA•OVA•OAD動畫${currentNum}${breakWord}${year}新番 10月新番 11月新番 12月新番 秋季新番${breakWord}${defaultTitle}`;
          keywords = `${year}年10月新番,${year}年秋新番,${year}年十月新番,${defaultKeywords},${defaultShortTailKeywords},${defaulLongTailtKeywords}`;
          break;
        case "Q0":
        default:
          if (year === "0000") {
            year = "未定放映日";
          }
          title = `${year}動畫${breakWord}新番表${breakWord}${defaultTitle}`;
          description = `${year}年動畫${breakWord}新番表${breakWord}日本電視•電影•ONA•OVA•OAD動畫${currentNum}${breakWord}${year}新番 冬季新番 春季新番 夏季新番 秋季新番${breakWord}${defaultTitle}`;
          keywords = `${year}年新番,${defaultKeywords},${defaultShortTailKeywords},${defaulLongTailtKeywords}`;
          break;
      }
      break;

    case "anime:playlist":
      canonical += "playlist/" + id;
      title = title + breakWord + defaultTitle;
      description = title;
      break;

    case "company":
      canonical += "company";
      description = `公司・工作室${breakWord}Grid Anime 動畫新番舊番整合`;
      title = `公司・工作室${breakWord}Grid Anime 動畫新番舊番整合`;
      break;

    case "company:cid":
      canonical += "company/" + id;
      description = `「${title}」動畫相關的資訊及資料整合${breakWord}公司・工作室${breakWord}${defaultTitle}`;
      title = `${title}${breakWord}公司・工作室${breakWord}${defaultTitle}`;
      break;

    case "music:music":
    case "music:musicname":
      canonical += "music/" + id;
      title = title + breakWord + "音樂" + breakWord + defaultTitle;
      description =
        defaultDescription + breakWord + "音樂" + breakWord + defaultTitle;
      break;

    case "staff:":
    case "actor:":
    case "character:":
    case "musician:":
    case "tag:":
      page = page.replace(":", "");
      canonical += page + "/" + id;
      title = title + breakWord + defaultTitle;
      description = defaultDescription + breakWord + defaultTitle;
      break;

    default:
      canonical += page;
      title = title + breakWord + defaultTitle;
      description = defaultDescription + breakWord + defaultTitle;
      break;
  }

  document.title = title;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={canonical} />
      <link rel="alternate" href={canonical} />

      {/* facebook meta */}
      <meta property="og:site_name" content={defaultSite_name} />
      <meta property="og:url" content={defaultUrl} />
      <meta property="og:type" content={defaultType} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:secure_url" content={image} />

      {/* Twitter Meta */}
      <meta name="twitter:card" content="summary" />
      <meta property="twitter:domain" content="gridanime.com" />
      <meta property="twitter:url" content={defaultUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <meta name="language" content="zh-HK" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>
  );
};

export default Head;
