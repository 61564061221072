import React from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();

  return (
    <header id="header" className="py-4">
      <div className="grid grid-cols-1 select-none text-center m-auto md:text-left mx-4">
        <label className={`font-VT323 m-0 p-0 text-5xl`}>
          {location.pathname === "/" ? (
            <Link to="/" className="text-primary">
              Grid Anime
            </Link>
          ) : (
            <Link to="/">Grid Anime</Link>
          )}
        </label>
      </div>
    </header>
  );
};

export default Header;
