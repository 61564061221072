import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const TranslationBread = () => {
  const [language, setLanguage] = useState("zh");

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const { i18n, t } = useTranslation();

  return (
    <div>
      <div className="dropdown-hover dropdown-top dropdown">
        <label className="btn w-32 p-2 md:w-52">翻譯糕（未完成）</label>
        <ul className="dropdown-content menu grid h-fit max-h-72 w-full overflow-y-auto border-4 border-base-300 bg-base-200">
          <li>
            <button onClick={() => changeLanguage("zh")}>中文</button>
          </li>
          <li>
            <button onClick={() => changeLanguage("ja")}>日本語</button>
          </li>
          <li>
            <button onClick={() => changeLanguage("en")}>English</button>
          </li>
          {/* <li>
                        <button>{i18n.language}</button>
                    </li> */}
        </ul>
      </div>
    </div>
  );
};

export default TranslationBread;
