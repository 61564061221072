import { Link } from "react-router-dom";

export const BaseLink = (props) => {
  return <Link to={props.link} target="_blank">{props.children}</Link>;
};

export const TargetLink = (props) => {
  return (
    <Link to={props.link} target={props.target}>
      {props.children}
    </Link>
  );
};

export const BackALink = (prop) => {
  return (
    <a href={prop.href} target="_blank" rel="noreferrer noopener">
      {prop.children}
    </a>
  );
};

export const TargetALink = (prop) => {
  // _blank
  // _self
  // _parent
  // _top
  // framename
  return (
    <a href={prop.href} target={prop.target} rel="noreferrer noopener nofollow">
      {prop.children}
    </a>
  );
};
